@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.tradingHistoryHead
	margin-bottom: rems(16)
	display: flex
	
	&__date
		max-width: 50%
		width: 100%
		@include media-breakpoint-up(md)
			max-width: rems(180)

		.bo-calendar .MuiInputBase-root
			height: rems(42)
			
	&__select
		max-width: 50%
		margin-right: rems(16)
		width: 100%
		
		@include media-breakpoint-up(md)
			max-width: rems(180)
			