@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.auth-popup
	padding: rems(50) rems(30) rems(30)
	background-color: $grey-form
	border: 2px solid rgba(112, 112, 112, 0.5)
	border-radius: 8px
	max-width: 547px
	margin: 0 rems(12)
	
	&__title
		font-weight: 600
		@include fontSize(18,29)
		color: $white
		text-align: center

	&__btns
		display: flex
		margin-top: rems(24)
		

	&__btn
		width: 100%
		
	&__continue
		text-align: center
		text-decoration: underline
		color: $orange
		@include fontSize(14,25)
		margin: rems(16) auto 0
		display: block