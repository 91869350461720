@import '../../../styles/mixins'
@import '../../../styles/variables'
@import '../../../styles/functions'

.info
	margin: rems(20) rems(16)  13%

	@include media-breakpoint-up(md)
		margin: rems(20) rems(30) 13%

	&__title
		font-weight: 600
		color: $white
		@include fontSize(26,32)
		margin-bottom: rems(35)

		@include media-breakpoint-up(md)
			@include fontSize(32,51)

	&__wrapper
		background-color: $grey-form
		border-radius: 8px
		padding: rems(16)

		@include media-breakpoint-up(md)
			padding: rems(30)

	&__container
		@include media-breakpoint-up(lg)
			max-width: 94%

.info-sec
	& + &
		margin-top: rems(35)

	&__title
		font-weight: 600
		@include fontSize(18,29)
		color: $white
		margin: rems(35) 0 rems(8)

	&__text
		font-weight: 500
		@include fontSize(14,24)
		color: $white
		margin: rems(8) 0
		& + &
			margin-top: rems(24)

		&--weight,b
			font-weight: 600
			font-family: $font-additional
			margin: rems(8) 0
			
		a
			color: $orange

	&__list
		list-style-type: disc
		padding-left: rems(24)

	&__item
		margin: rems(8) 0
		& + &
			margin-top: rems(24)

		.info-sec__text
			margin: 0