@import '../../../../styles/mixins'
@import '../../../../styles/variables'
@import '../../../../styles/functions'

.internal-content
	height: 100%
	padding: rems(20) rems(16) 15%
	background-position: top center
	background-repeat: no-repeat
	//background-size: 85%
	position: relative

	@include media-breakpoint-up(md)
		padding: rems(20) rems(30) 15%