@import '../../../../styles/mixins'
@import '../../../../styles/variables'
@import '../../../../styles/functions'

.status
	padding: rems(7) rems(10)
	border-radius: 4px
	display: inline-flex
	align-items: center

	&.verified
		background-color: $green

	&.unverified
		background-color: #D33031

	&.pending
		background-color: #FFB437

	&__icon
		margin-right: rems(10)
		height: 20px
		width: 20px


	&__text
		color: $white
		font-weight: 500
		@include fontSize(12,19)
		text-overflow: ellipsis
		overflow: hidden
		white-space: nowrap