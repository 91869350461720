@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.tabs
	display: flex
	overflow-x: auto
	.MuiTab-root
		padding: rems(25) rems(39)
		border-radius: 8px 8px 0px 0px
		color: $white!important
		font-weight: 600
		@include fontSize(12,22)
		white-space: nowrap
		text-transform: none
		font-family: $font-base

	.MuiTabs-indicator
		display: none

	.Mui-selected
		background-color: $grey-form

.tabs-t-history
	display: flex
	flex-direction: column
	overflow-x: unset
	align-items: flex-start

	@include media-breakpoint-up(md)
		display: flex
		flex-direction: row
		
	&__btns
		display: flex
		order: -1
		margin-bottom: rems(16)
		
		@include media-breakpoint-up(md)
			margin-bottom: 0
			margin-left: auto
			order: 0
		
	&__btn
		& + &
			margin-left: rems(12)

.tab-content
	overflow-x: auto

	&--scroll
		padding: rems(16)
		background-color: $grey-form

		@include media-breakpoint-up(md)
			border-radius: 0px 8px 8px 8px
			padding: rems(30)

	&.MuiTabPanel-root
		padding: rems(16)
		border-radius: 0px 8px 8px 8px
		background-color: $grey-form

		@include media-breakpoint-up(md)
			padding: rems(30)

	&.tab-content--mini
		&.MuiTabPanel-root
			padding: rems(15)

.tab-warning
	padding: rems(16)
	border-radius: 0px 8px 8px 8px
	background-color: $grey-form

	@include media-breakpoint-up(md)
		padding: rems(30)

	&__title
		font-weight: 600
		color: $white
		@include fontSize(26,42)

		@include media-breakpoint-up(md)
			@include fontSize(32,51)

	&__text
		color: $white
		@include fontSize(14,25)
		font-weight: 400

	&__header
		margin-bottom: rems(24)


	&__btn
		width: 100%

		@include media-breakpoint-up(b680)
			width: auto
