@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.base-wrap
	position: relative
	z-index: 1
	display: flex
	flex-direction: column
	height: 100%
	width: 100%
	overflow-x: clip
	&__header
		flex: 0 0 auto
	&__content
		flex: 1 0 auto
	&__footer
		flex: 0 0 auto


.main-content
	height: 100%
	display: flex

	&__wrapper
		width: 100%
		@include media-breakpoint-up(md)
			width: calc(100% - 62px)
