@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.trading
	width: 100%
	height: 100%
	display: flex
	flex-direction: column

	@include media-breakpoint-up(md)
		flex-direction: row
		height: calc(100vh - 78px)