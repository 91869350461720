@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.section-title
	font-weight: 600
	@include fontSize(30,42)
	color: $white

	@include media-breakpoint-up(lg)
		@include fontSize(45,63)

.wrapper
	margin: 0 auto
	padding: 12px
	max-width: calc(368px + 24px)

	@include media-breakpoint-up(lg)
		max-width: calc(758px + 24px)

	@include media-breakpoint-up(b1400)
		max-width: calc(1146px + 24px)

.hero
	position: relative
	padding-top: rems(90)
	padding-bottom: 282px

	@include media-breakpoint-up(lg)
		padding-top: rems(140)
		padding-bottom: 32%

	@include media-breakpoint-up(b1400)
		padding: rems(130) 0 32%

	&__wrapper
		display: flex
		justify-content: center

	&__content
		max-width: 368px
		width: 100%
		text-align: center

		@include media-breakpoint-up(lg)
			max-width: 794px

	&__title
		font-weight: 600
		@include fontSize(35,49)
		color: $white
		margin-bottom: rems(20)

		@include media-breakpoint-up(lg)
			margin-bottom: rems(40)
			@include fontSize(55,77)

		@include media-breakpoint-up(b1400)
			@include fontSize(65,91)

	&__subtitle
		font-weight: 500
		@include fontSize(20,28)
		color: rgba($white, 0.5)
		margin: 0 auto

		@include media-breakpoint-up(lg)
			max-width: 530px

	&__btn
		margin-top: rems(60)

		@include media-breakpoint-up(lg)
			margin-top: rems(40)

	&__bg-left
		position: absolute
		top: 10%
		right: 50%
		z-index: -1
		display: none
		opacity: 0.1

		@include media-breakpoint-up(lg)
			display: block

	&__bg-right
		position: absolute
		top: 10%
		left: 75%
		z-index: -1
		display: none
		opacity: 0.1

		@include media-breakpoint-up(lg)
			display: block

	&__bg
		position: absolute
		left: 50%
		top: 25%
		transform: translateX(-50%)
		z-index: -2
		width: 100%
		max-width: rems(1149)
		display: none

		@include media-breakpoint-up(lg)
			display: block


		&-mobile
			position: absolute
			bottom: 0
			width: 100%
			max-height: 313px
			object-fit: contain
			object-position: left

			display: block

			@include media-breakpoint-up(lg)
				display: none

.conditions
	padding-bottom: rems(114)

	@include media-breakpoint-up(lg)
		padding-bottom: rems(162)

	@include media-breakpoint-up(b1400)
		padding-bottom: rems(234)

	&__header
		margin-bottom: rems(20)

		@include media-breakpoint-up(lg)
			margin-bottom: rems(45)

	&__title
		max-width: 682px
		text-align: center
		margin: 0 auto

.condition-card
	padding: rems(30)
	border-radius: 15px
	background-color: $dark-grey2
	height: 100%

	@include media-breakpoint-up(lg)
		height: 100%

	&__text
		color: $white
		font-weight: 500
		@include fontSize(14,20)

		@include media-breakpoint-up(lg)
			@include fontSize(20,28)

	&__title
		font-weight: 700
		color: $white
		@include fontSize(28,42)
		margin-bottom: rems(15)

		@include media-breakpoint-up(lg)
			@include fontSize(45,63)
			margin-bottom: rems(30)

.why
	position: relative
	padding-bottom: rems(300)

	@include media-breakpoint-up(lg)
		padding-bottom: rems(197)

	@include media-breakpoint-up(b1400)
		padding-bottom: rems(140)

	&:before
		position: absolute
		content: ''
		left: -12%
		top: -21%
		width: 1101px
		height: 724px
		background: url("../../../assets/images/swich.svg")
		z-index: -1
		display: none

		@include media-breakpoint-up(lg)
			display: block

		@include media-breakpoint-up(b1400)
			left: 26px


	&__header
		margin-bottom: rems(22)

		@include media-breakpoint-up(lg)
			margin-bottom: rems(35)

		@include media-breakpoint-up(b1400)
			margin-bottom: rems(45)

	&__title
		text-align: center

.why-card
	min-height: 500px
	padding: rems(35) rems(45)
	border-radius: 15px
	background-color: $dark-grey2
	border: 2px solid $border
	position: relative
	overflow: hidden

	@include media-breakpoint-up(b1400)
		min-height: 574px

	&--big
		min-height: 676px

		@include media-breakpoint-up(lg)
			min-height: 504px

		@include media-breakpoint-up(b1400)
			min-height: 470px

		.why-card__wrap
			@include media-breakpoint-up(b1400)
				max-width: 527px

	&__wrap
		position: relative
		z-index: 1

	&__icon
		margin-bottom: rems(20)

	&__title
		letter-spacing: 0.05em
		font-weight: 700
		@include fontSize(30,39)
		color: $white
		margin-bottom: rems(20)

		@include media-breakpoint-up(b1400)
			@include fontSize(35,45)

	&__text
		font-weight: 400
		color: rgba($white, 0.5)
		@include fontSize(16,22)

		@include media-breakpoint-up(b1400)
			@include fontSize(18,25)

	&__pic
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		height: 100%
		width: 100%

		img
			height: 100%
			width: 100%
			object-fit: cover


.offer
	padding-bottom: rems(112)

	@include media-breakpoint-up(lg)
		padding-bottom: rems(336)

	@include media-breakpoint-up(b1400)
		padding-bottom: rems(223)

	&__wrapper
		border-radius: 15px
		background-color: $dark-grey2
		padding: rems(44) rems(35) rems(362)
		position: relative
		overflow: hidden

		@include media-breakpoint-up(lg)
			padding: rems(52) rems(35)

		@include media-breakpoint-up(b1400)
			padding: rems(62) rems(35)


	&__left
		position: relative
		z-index: 1

		@include media-breakpoint-up(lg)
		max-width: 315px

		@include media-breakpoint-up(b1400)
			max-width: 580px

	&__title
		font-weight: 700
		@include fontSize(30,39)
		color: $white
		letter-spacing: 0.05em
		margin-bottom: rems(20)

		@include media-breakpoint-up(b1400)
			@include fontSize(35,45)

	&__text
		font-weight: 500
		@include fontSize(16,22)
		color: rgba($white,0.5)

		@include media-breakpoint-up(b1400)
			@include fontSize(20,28)

	&__btn
		margin-top: rems(50)
		@include fontSize(14,25)

		@include media-breakpoint-up(lg)
			@include fontSize(16,29)

	&__pic
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		height: 100%
		width: 100%

		img
			height: 100%
			width: 100%
