@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.auth
	padding-top: rems(30)
	padding-bottom: rems(45)
	height: 100%

	@include media-breakpoint-up(lg)
		background: url("../../../assets/images/auth-bg-lg.png") no-repeat center
		padding-top: rems(94)
		padding-bottom: rems(114)

	@include media-breakpoint-up(b1400)
		background: url("../../../assets/images/auth-bg.png") no-repeat center
		padding-top: rems(77)
		padding-bottom: rems(136)

	&__wrapper
		display: flex
		justify-content: center

.auth-form
	padding: rems(30)
	width: 100%
	max-width: 470px

	@include media-breakpoint-up(lg)
		background-color: $grey-form
		border-radius: 8px

	&__title
		font-weight: 600
		@include fontSize(32,51)
		margin-bottom: rems(12)

	&__text
		font-weight: 400
		@include fontSize(14,25)
		color: $white
		margin-bottom: rems(24)

		a
			color: $orange

	&__inp-wrap
		margin-bottom: rems(25)

		@include media-breakpoint-up(lg)
			margin-bottom: rems(16)

	&__list-pattern
		margin-top: 16px
		padding-top: 16px
		border-top: 1px solid #393939

	&__list-agree

	&__btn
		margin-top: rems(60)
		width: 100%

		@include media-breakpoint-up(md)
			display: block!important
			margin-top: rems(24)
			width: auto

	&__link
		display: block
		color: $orange
		font-weight: 400
		@include fontSize(14,25)
		margin-bottom: rems(16)

	&__back
		display: flex
		align-items: center
		color: $orange
		font-weight: 500
		@include fontSize(16,29)
		margin-bottom: rems(24)

		img
			margin-right: rems(8)

	&__error
		color: $red
		@include fontSize(14,25)
		font-weight: 500
		margin: rems(4) 0

.list-pattern
	&__item
		display: flex
		align-items: center

		& + &
			margin-top: rems(10)

		svg
			margin-right: rems(15)

	&__text
		color: $grey
		font-weight: 400
		@include fontSize(14,25)


.list-agree
	margin-top: rems(20)

	&__item
		& + &
			margin-top: rems(20)

.completed
	padding: rems(30)
	border-radius: 8px
	text-align: center
	max-width: rems(470)
	width: 100%

	@include media-breakpoint-up(lg)
		background-color: $grey-form
		margin-bottom: rems(300)

	&__icon
		display: block
		margin-bottom: rems(10)

	&__title
		font-weight: 600
		@include fontSize(32,51)
		color: $white
		margin-bottom: rems(10)

	&__text
		font-weight: 400
		@include fontSize(14,25)
		color: $white

	&__btn
		margin-top: 24px

.success
	p
		color: $green

	path
		fill: $green

.fail
	path
		fill: $red

	p
		color: $red
